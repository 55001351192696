import { formatDate } from "@/utils/datetime";
import type { IMilestoneReports } from "@/modules/charts-dashboard/services/data/milestone-reports/milestone.interface";

const addSymbolToNumber = (number: number) =>
  number > 0 ? `+${number}` : number;

const progressTableColumns = [
  {
    prop: "company",
    label: "Company",
    width: 200,
  },
  {
    prop: "levels",
    label: "Levels",
  },
  {
    prop: "milestones",
    label: "Milestones",
  },
  {
    prop: "startVIL",
    label: "Start VIL",
  },
  {
    prop: "endVIL",
    label: "End VIL",
  },
  {
    prop: "growthCategory",
    label: "Growth Categroy",
    width: 200,
  },
  {
    prop: "latestAssement",
    label: "Latest Assesment",
    width: 250,
  },
];
export const defaultProgressTableData = {
  rows: [
    {
      company: "Pixelmatters",
      levels: "0",
      milestones: "+12",
      startVIL: 6,
      endVIL: 6,
      growthCategory: "Problem and Vision +4",
      latestAssement: "May 13, 2024 10:50 AM",
    },
    {
      company: "Uniplaces",
      levels: "+1",
      milestones: "+5",
      startVIL: 4,
      endVIL: 5,
      growthCategory: "Growth +4",
      latestAssement: "Aug 13, 2019 10:39 AM",
    },
    {
      company: "Naborforce",
      levels: "+2",
      milestones: "+4",
      startVIL: 3,
      endVIL: 5,
      growthCategory: "Product +4",
      latestAssement: "Aug 13, 2019 10:39 AM",
    },
    {
      company: "Roundetrip",
      levels: "-1",
      milestones: "+8",
      startVIL: 3,
      endVIL: 2,
      growthCategory: "Team -3",
      latestAssement: "Aug 13, 2019 10:39 AM",
    },
    {
      company: "Humanitru",
      levels: "+1",
      milestones: "+2",
      startVIL: 1,
      endVIL: 2,
      growthCategory: "Team +3",
      latestAssement: "Aug 13, 2019 10:39 AM",
    },
  ],
  footer: () => [
    "2024 Cohort",
    "Avg. 1.5",
    "Avg. 4.5",
    "Avg. 2.25",
    "Avg. 4",
    "Value Prop +10",
    "Aug 13, 2019 10:39 AM",
  ],
  showFooter: true,
  columns: progressTableColumns,
};
export const defaultHighlightData = [
  {
    title: "Achievment Metrics",
    icon: "&#128200;",
    items: [
      {
        title: `Total Levels Achieved: 7`,
        subtitle: `Avg. Levels Achieved: 1.5`,
      },
      {
        title: `Total Milestones Achieved: 20`,
        subtitle: `Avg. Milestones Achieved: 4.5`,
      },
    ],
  },
  {
    title: "Company Higlights",
    icon: "&#128188;",
    items: [
      {
        title: "Largest level increase:",
        subtitle: `Nabarforce with 3 to 5`,
      },
      {
        title: "Largest milestone growth:",
        subtitle: `Pixelmatters with 16 milestones`,
      },
      {
        title: "Companies with no level change:",
        subtitle: "Pixelmatters",
      },
    ],
  },
  {
    title: "Category Higlights",
    icon: "&#127775;",
    items: [
      {
        title: "Largest category growth:",
        subtitle: `Value Proposition +10`,
      },
      {
        title: "Lowest categroy growth:",
        subtitle: `Business Model -3`,
      },
    ],
  },
];
export const defaultAssesmentTableData = {
  rows: [
    {
      company: "Pixelmatters",
      vil: 6,
      team: 6,
      pv: 7,
      VP: 6,
      product: 7,
      Market: 9,
      bm: 8,
      scale: 6,
      exit: 6,
      assessmentDate: "Apr 13, 2024 10:50 AM",
    },
    {
      company: "Uniplaces",
      vil: 5,
      team: 5,
      pv: 6,
      VP: 8,
      product: 6,
      Market: 6,
      bm: 5,
      scale: 5,
      exit: 5,
      assessmentDate: "Apr 13, 2024 10:50 AM",
    },
    {
      company: "Naborforce",
      vil: 5,
      team: 5,
      pv: 5,
      VP: 5,
      product: 5,
      Market: 5,
      bm: 5,
      scale: 6,
      exit: 6,
      assessmentDate: "Apr 13, 2024 10:50 AM",
    },
    {
      company: "Roundtrip",
      vil: 2,
      team: 2,
      pv: 3,
      VP: 2,
      product: 3,
      Market: 2,
      bm: 2,
      scale: 2,
      exit: 2,
      assessmentDate: "Apr 13, 2024 10:50 AM",
    },
    {
      company: "Humanitru",
      vil: 1,
      team: 1,
      pv: 1,
      VP: 1,
      product: 1,
      Market: 1,
      bm: 1,
      scale: 4,
      exit: 1,
      assessmentDate: "Apr 13, 2024 10:50 AM",
    },
  ],
  columns: [
    {
      prop: "company",
      label: "Company",
      width: 100,
    },
    {
      prop: "vil",
      label: "vil",
    },
    {
      prop: "team",
      label: "Team",
    },
    {
      prop: "pv",
      label: "PV",
    },
    {
      prop: "VP",
      label: "vp",
    },
    {
      prop: "product",
      label: "Product",
    },
    {
      prop: "Market",
      label: "market",
    },
    {
      prop: "bm",
      label: "BM",
    },
    {
      prop: "scale",
      label: "Scale",
    },
    {
      prop: "exit",
      label: "Exit",
    },
    {
      prop: "assessmentDate",
      label: "Assessment Date",
      width: 250,
    },
  ],
  showFooter: false,
};

export const createProgressTableData = (
  data: IMilestoneReports,
  listTitle: string,
  returnDefault: boolean,
) => {
  if (!data || returnDefault) {
    return defaultProgressTableData;
  }

  const rows = [];
  const assesmentDates: any = [];

  for (const company of data.companies) {
    const assesmentDate = new Date(company.last_assessment.date);

    rows.push({
      company: company.company_name,
      levels: addSymbolToNumber(company.vil_level_progress),
      milestones: addSymbolToNumber(company.milestone_progress),
      startVIL: company.first_assessment.vil,
      endVIL: company.last_assessment.vil,
      growthCategory: `${company.max_progress_category.name} ${addSymbolToNumber(company.max_progress_category.milestones)}`,
      latestAssement: formatDate(assesmentDate),
    });

    assesmentDates.push(assesmentDate);
  }

  // @ts-expect-error Not properly typed
  assesmentDates.sort((a: Date, b: Date) => b - a);
  const footer = () => [
    listTitle,
    data.vil_totals.level,
    data.vil_totals.milestones,
    data.vil_averages.start_vil,
    data.vil_averages.end_vil,
    `${data.categories_insights.max_progress_category.name} ${addSymbolToNumber(data.categories_insights.max_progress_category.milestones)}`,
    formatDate(assesmentDates[0]),
  ];

  return {
    rows,
    footer,
    showFooter: true,
    columns: progressTableColumns,
  };
};

export const createHiglightsData = (
  data: IMilestoneReports,
  returnDefault: boolean,
) => {
  if (!data || returnDefault) {
    return defaultHighlightData;
  }

  const highlights = [];
  const noChangeCompanies = [];

  let totalLevelsAchieved = 0;
  let averageLevelsAchieved = 0;
  let totalMilestonesAchieved = 0;
  let averageMilestonesAchieved = 0;

  for (const company of data.companies) {
    totalLevelsAchieved += company.vil_level_progress;
    totalMilestonesAchieved += company.milestone_progress;
    averageMilestonesAchieved = totalMilestonesAchieved / data.companies.length;

    if (company.vil_level_progress === 0) {
      noChangeCompanies.push(company.company_name);
    }
  }

  averageLevelsAchieved = totalLevelsAchieved / data.companies.length;
  // Reducing the number of companies currently being shown in the table
  noChangeCompanies.splice(0, noChangeCompanies.length - 2);

  const column1 = {
    title: "Achievment Metrics",
    icon: "&#128200;",
    items: [
      {
        title: `Total Levels Achieved: ${data.vil_totals.level}`,
        subtitle: `Avg. Levels Achieved: ${averageLevelsAchieved}`,
      },
      {
        title: `Total Milestones Achieved: ${data.vil_totals.milestones}`,
        subtitle: `Avg. Milestones Achieved: ${averageMilestonesAchieved}`,
      },
    ],
  };
  const column2 = {
    title: "Company Higlights",
    icon: "&#128188;",
    items: [
      {
        title: "Largest level increase:",
        subtitle: `${data.company_insights.max_vil_level_progress.company_name} with ${data.company_insights.max_vil_level_progress.first_assessment_level} to ${data.company_insights.max_vil_level_progress.last_assessment_level}`,
      },
      {
        title: "Largest milestone growth:",
        subtitle: `${data.company_insights.max_milestones_progress.company_name} with ${data.company_insights.max_milestones_progress.milestones} milestones`,
      },
      {
        title: "Companies with no level change:",
        subtitle: noChangeCompanies.join(" "),
      },
    ],
  };
  const column3 = {
    title: "Category Higlights",
    icon: "&#127775;",
    items: [
      {
        title: "Largest category growth:",
        subtitle: `${data.categories_insights.max_progress_category.name} ${addSymbolToNumber(data.categories_insights.max_progress_category.milestones)}`,
      },
      {
        title: "Lowest categroy growth:",
        subtitle: `${data.categories_insights.lowest_progress_category.name} ${addSymbolToNumber(data.categories_insights.lowest_progress_category.milestones)}`,
      },
    ],
  };

  highlights.push(column1);
  highlights.push(column2);
  highlights.push(column3);

  return highlights;
};

export const createAssessmentTableData = (
  data: IMilestoneReports,
  returnDefault: boolean,
) => {
  if (!data || returnDefault) {
    return defaultAssesmentTableData;
  }

  const rows: any[] = [];
  const columns: any[] = [
    {
      prop: "company",
      label: "Company",
      width: 100,
    },
  ];

  let assessmentDates = [];

  for (const company of data.companies) {
    const row: Record<string, any> = {};

    row["company"] = company.company_name.substring(0, 10);
    row["vil"] = company.vil_level_progress;
    row["assessmentDate"] = formatDate(company.last_assessment.date);

    assessmentDates.push(new Date(company.last_assessment.date));
    assessmentDates.push(new Date(company.first_assessment.date));

    for (const category of company.categories_progress) {
      const categoryId = category.name
        .split(" ")
        .map((t) => t.toLocaleLowerCase())
        .join("-");

      row[categoryId] = category.milestones;

      if (columns.length !== company.categories_progress.length) {
        columns.push({
          prop: categoryId,
          label: category.name,
        });
      }
    }

    rows.push(row);
  }

  // @ts-expect-error improper type
  assessmentDates = assessmentDates.sort((a: Date, b: Date) => b - a);

  columns.push({
    prop: "assessmentDate",
    label: "Assessment Date",
    width: 250,
  });

  return {
    rows,
    columns,
    showFooter: false,
    assessmentDates: {
      start: formatDate(
        assessmentDates[assessmentDates.length - 1],
        "MMM DD, YYYY",
      ),
      end: formatDate(assessmentDates[0], "MMM DD, YYYY"),
    },
  };
};
