import { PX_CUSTOM_CHART_PLUGINS } from "@/components/charts/custom-bar-chart/types";

import type { IMilestoneReports } from "@/modules/charts-dashboard/services/data/milestone-reports/milestone.interface";
import type { TChartData } from "vue-chartjs/dist/types";

export const defaultLevelsChartData: {
  type: string;
  data: TChartData<"bar">;
} = {
  type: "bar",
  labels: [
    "2024 Cohort",
    "Pixelmatter VIL",
    "Uniplaces VIL",
    "Naborforce VIL",
    "Roundtrip VIL",
    "Humanitru VIL",
  ],
  data: {
    labels: [
      "2024 Cohort",
      "Pixelmatter VIL",
      "Uniplaces VIL",
      "Naborforce VIL",
      "Roundtrip VIL",
      "Humanitru VIL",
    ],
    datasets: [
      {
        backgroundColor: "transparent",
        barPercentage: 1,
        barThickness: 32,
        label: "Leveled Up",
        maxBarThickness: 32,
        minBarLength: 0,
        data: [
          // @ts-expect-error "incorrect types"
          [1, 5],
          // @ts-expect-error "incorrect types"
          [2, 7],
          // @ts-expect-error "incorrect types"
          [3, 3],
          // @ts-expect-error "incorrect types"
          [3.5, 4.5],
          // @ts-expect-error "incorrect types"
          [5, 2],
          // @ts-expect-error "incorrect types"
          [3, 1],
        ],
      },
    ],
  },
  options: {},
  plugins: [
    {
      id: PX_CUSTOM_CHART_PLUGINS.FAT_ARROW,
      options: {
        barColors: {
          positive: "#33798C",
          negative: "#B66E3F",
          neutral: "#AAA246",
        },
      },
    },
  ],
};

export const defaultMilestonesChartData = {
  type: "bar",
  data: {
    labels: [
      "2024 Cohort",
      "Pixelmatter VIL",
      "Uniplaces VIL",
      "Naborforce VIL",
      "Roundtrip VIL",
      "Humanitru VIL",
    ],
    datasets: [
      {
        label: "Levels Growth",
        barPercentage: 0.5,
        categoryPercentage: 1,
        barThickness: 70,
        maxBarThickness: 70,
        backgroundColor: "#33798C",
        minBarLength: 0,
        data: [
          [0, -2],
          [0, -2],
          [0, 2],
          [0, -2],
          [0, 3],
          [0, 4],
        ],
      },
      {
        label: "Milestones growth",
        barPercentage: 0.5,
        categoryPercentage: 1,
        barThickness: 70,
        backgroundColor: "#C0517F",
        maxBarThickness: 70,
        minBarLength: 0,
        data: [
          [0, 3],
          [0, 6],
          [0, -3],
          [0, -3],
          [0, 4],
          [0, 8],
        ],
      },
    ],
  },
  labels: [
    "2024 Cohort",
    "Pixelmatter VIL",
    "Uniplaces VIL",
    "Naborforce VIL",
    "Roundtrip VIL",
    "Humanitru VIL",
  ],
  options: {
    limits: {
      min: -10,
      max: 10,
    },
  },
};

export const defaultLevelsOverTimeChartData = {
  type: "line",
  data: {
    labels: ["January", "", "", "", "", "", "July"],
    datasets: [
      {
        label: "Multiple Companies Average",
        backgroundColor: "#8B8FA1",
        borderColor: "#8B8FA1",
        borderWidth: 4,
        data: [5, 4.5, 4.8, 4.6, 4.7, 6.2, 5.2],
      },
      {
        label: "Pixelmatters VIL",
        backgroundColor: "#4B92E5",
        borderColor: "#4B92E5",
        borderWidth: 2,
        data: [6.3, 6.8, 5.8, 6.1, 6.9, 6.6, 6.8],
      },
    ],
  },
  options: {
    elements: {
      point: {
        pointStyle: "circle",
        radius: 0,
      },
      line: {
        tension: 0.2,
      },
    },
  },
  plugins: [],
};

// Add support for return data with error so as to be able to a default
// ui that shows the user default data along with the error message.
export const createLevelsChartData = (
  data: IMilestoneReports,
  returnDefault: boolean,
) => {
  if (!data || returnDefault) {
    return defaultLevelsChartData;
  }

  try {
    const chartLabels = [];
    const dataPoints = [];
    let chartData = {};

    for (const company of data.companies) {
      chartLabels.push(`${company.company_name.substring(0, 7)}... VIL`);
      dataPoints.push([
        company.last_assessment?.vil,
        company.first_assessment?.vil === company.last_assessment?.vil
          ? company.last_assessment?.vil + 2
          : company.last_assessment?.vil,
      ]);
    }

    chartData = {
      labels: chartLabels,
      datasets: [
        {
          barPercentage: 1,
          barThickness: 32,
          maxBarThickness: 32,
          minBarLength: 0,
          label: "Leveled Up",
          backgroundColor: "transparent",
          data: dataPoints,
        },
      ],
    };

    return {
      type: "bar",
      data: chartData,
      labels: chartLabels,
      plugins: [
        {
          id: PX_CUSTOM_CHART_PLUGINS.FAT_ARROW,
          options: {
            barColors: {
              positive: "#33798C",
              negative: "#B66E3F",
              neutral: "#AAA246",
            },
          },
        },
      ],
      options: {},
    };
  } catch (err) {
    console.log(err);
  }
};

export const createMilestonesChartData = (
  data: IMilestoneReports,
  returnDefault: boolean,
) => {
  if (!data || returnDefault) {
    return defaultMilestonesChartData;
  }

  try {
    let chartData = {};
    const labels = [];
    const chartLimits = {
      min: 0,
      max: 0,
    };
    const vilDataPoints = [];
    const milestoneDataPoints = [];

    let chartPoints = [];

    for (const company of data.companies) {
      labels.push(`${company.company_name.substring(0, 7)}... VIL`);
      vilDataPoints.push([0, company.vil_level_progress]);
      milestoneDataPoints.push([0, company.milestone_progress]);

      chartPoints.push(company.vil_level_progress);
      chartPoints.push(company.milestone_progress);
    }

    chartPoints = chartPoints
      .map((n) => (n < 0 ? n * -1 : n))
      .sort((a, b) => b - a);

    chartLimits.min = chartPoints[0] * -1;
    chartLimits.max = chartPoints[0];

    chartData = {
      labels,
      datasets: [
        {
          label: "Levels Growth",
          barPercentage: 1,
          barThickness: 70,
          maxBarThickness: 70,
          backgroundColor: "#33798C",
          minBarLength: 0,
          data: vilDataPoints,
        },
        {
          label: "Milestones growth",
          barPercentage: 1,
          barThickness: 70,
          backgroundColor: "#C0517F",
          maxBarThickness: 70,
          minBarLength: 0,
          data: milestoneDataPoints,
        },
      ],
    };

    return {
      type: "bar",
      plugins: [],
      options: {
        limits: chartLimits,
      },
      data: chartData,
      labels: labels,
    };
  } catch (err) {
    console.log(err);
  }
};

export const createLevelsOverTimeChartData = (
  data: IMilestoneReports,
  returnDefault: boolean,
) => {
  if (!data || returnDefault) {
    return defaultLevelsOverTimeChartData;
  }

  // const chartData = {};
  // const chartPoints = {};

  // const labels = [];

  // for(const company in data.companies) {

  // }
};
